// $primaryColor:#9FA8DA !default;
// $primaryTextColor:#121212 !default;
$accentColor:#175025 !default;
$accentTextColor:#121212 !default;

$primaryColor:#279242 !default;
$primaryDarkColor:#207435;
$primaryDarkerColor:#175025;
$primaryTextColor:#ffffff !default;

@import '../../variables';
@import '../../../_compact';
