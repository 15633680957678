@import './variables';
@import './_fonts';
@import '../../../../designer/components';
@import '../../../extensions/vendor_extensions';
@import './_extensions';

a{
    //text-decoration-color: aqua;
    color: $primaryColor;
    font-family: Roboto, Helvetica, Arial, sans-serif!important;
}