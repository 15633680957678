/* You can add global styles to this file, and also import other style files
body, h1, h2, h3, h4, h5, button, a, .ui-dropdown, input, label, table, .title {
    font-family: "Roboto", Helvetica, Arial, sans-serif; 
}

i {
    font-size: 22px;
  } */

  #preloader {
    overflow: hidden;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
    background-color: black; }
  
  .opsviewer-load {
    -webkit-animation: 2000ms linear 0s normal none infinite running opsviewer-load;
    animation: 2000ms linear 0s normal none infinite running opsviewer-load;
    background-color: transparent;
    border-top-color: #4db748;
    border-left-color: #4db748;
    border-right-color: #4db748;
    border-radius: 50%;
    border-style: solid;
    border-width: 6px;
    height: 50px;
    left: calc(50% - 25px);
    position: relative;
    top: calc(50% - 25px);
    width: 50px;
    z-index: 9; }

    @-webkit-keyframes opsviewer-load {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg); } }
      @keyframes opsviewer-load {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg); } }
